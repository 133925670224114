
<template>
  <div class="coupons">
      <div class="row">
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3 bg-light d-flex align-items-center justify-content-between p-2">
                                <h5 class="my-0">
                                <i class="mdi mdi-playlist-edit mr-1"></i> Coupon Codes
                                </h5>
                                <b-button variant="primary" class="px-1 py-0" @click.prevent="popupModalShow = true" type="button">
                                    <i class="mdi font-14 mdi-plus"></i></b-button>
                            </div>
                            <div v-if="!isLoading" role="tablist" class="mb-3">
                                <b-card no-body class="mb-1 shadow-none" v-for="(coupon, index) in coupons" :key="index">
                                    <b-card-header header-tag="header" class="bg-transparent px-2" role="tab">
                                        <div class="d-flex">
                                            <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                                                <a v-b-toggle="`couponAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                                                    <i class="mdi mdi-check mr-1 text-primary"></i>{{coupon.code}} - {{coupon.is_active ? 'Active' : 'Not Active'}}
                                                </a>
                                            </h5>
                                            <span class="float-right">
                                                <b-dropdown variant="light" size="sm" right>
                                                    <template slot="button-content">
                                                        <i class="mdi mdi-dots-vertical"></i>
                                                    </template>
                                                    <b-dropdown-item-button @click="initEdit(coupon)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                                                    <b-dropdown-item-button @click="deleteItem(coupon.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
                                                </b-dropdown>
                                            </span>
                                        </div>
                                    </b-card-header>
                                    <b-collapse :id="`couponAccordion-${index}`" accordion="accordion" role="tabpanel">
                                        <b-card-body>
                                            <h6>Multiplier: {{coupon.multiplier}} - {{coupon.multiplier*100}}% deduction will be applied to the total charge.</h6>
                                            <b-card-text>{{coupon.description}}</b-card-text>
                                            <b-card-text>Valid from <strong>{{coupon.start | date(true)}}</strong> to <strong>{{coupon.end | date(true)}}</strong></b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <is-loading v-else />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered :title="editMode? 'Update Coupon':'Add Coupon'" title-class="font-18" @hide="initializeAll()">
      <div class="">
        <div class="form-group mb-3">
            <label for="couponCode">
                Code
                <span class="text-danger">*</span>
            </label>
            <input type="text" v-validate="{required: true}"
            v-model="newCoupon.code" id="couponCode" name="code"
            class="form-control" :class="{ 'is-invalid': errors.has('code') }" placeholder="Enter Code" />
            <span class="text-danger invalid-feedback"  v-show="errors.has('code')">{{ errors.first('code') }}</span>
        </div>
        <div class="form-group mb-3">
            <label for="couponMultiplier">
                    Multiplier
                <span class="text-danger">*</span>
            </label>
            <input type="number" v-validate="{required: true}"
            v-model="newCoupon.multiplier" id="couponMultiplier" name="multiplier"
            class="form-control" :class="{ 'is-invalid': errors.has('multiplier') }" placeholder="E.g 0.1 for 10% discount" />
            <span class="text-danger invalid-feedback"  v-show="errors.has('multiplier')">{{ errors.first('multiplier') }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="d-block">Start Date</label>
            <date-picker v-model="newCoupon.start" placehoder="Choose Date"
            :editable="false" :first-day-of-week="1"
            type="datetime" format="YYYY-MM-DD hh:mm" lang="en"></date-picker>
        </div>
        <div class="form-group mb-3">
            <label class="d-block">End Date</label>
            <date-picker v-model="newCoupon.end" placehoder="Choose Date"
            :editable="false" :first-day-of-week="1"
            type="datetime" format="YYYY-MM-DD hh:mm" lang="en"></date-picker>
        </div>
        <div class="form-group mb-3">
            <b-form-checkbox v-model="newCoupon.is_active" name="is-active" switch>
                Active
            </b-form-checkbox>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="editMode? updateItem() : createItem()" :disabled="!isFormValid" type="button">{{editMode? 'Update':'Submit'}}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import IsLoading from '@/components/IsLoading.vue';

export default {
  components: {
    DatePicker,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      editMode: false,
      newCoupon: {
        code: "",
        multiplier: null,
        start: null,
        end: null,
        is_active: false,
        description: ""
      },
    }
  },
    computed: {
        isFormValid() {
            return !this.errors.any()
        },
        coupons(){
            return this.$store.state.coupons
        }
    },
    methods: {
        createItem(){
            let formData = new FormData();
            formData.append('data', JSON.stringify(this.newCoupon));
            this.resquestProcessor(formData, 'create')
        },
        updateItem(){
            let formData = new FormData()
            formData.append('data', JSON.stringify(this.newCoupon))
            this.resquestProcessor(formData, `${this.newCoupon.id}/update`, 'UPDATE')
        },
        resquestProcessor(formData, action, ftn='ADD'){
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/coupons/${action}`,formData)
            .then(response => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.initializeAll()
                    this.$store.commit(`${ftn}_COUPON`, response.data.data)
                }
            })
        },
        initEdit(coupon){
            this.newCoupon =  {
                id: coupon.id,
                code: coupon.code,
                multiplier: coupon.multiplier,
                start: new Date(coupon.start),
                end: new Date(coupon.end),
                is_active: coupon.is_active ? true : false,
                description: coupon.description
            }
            this.editMode = true;
            this.popupModalShow = true;
        },
        deleteItem(itemId){
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to delete a coupon!",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#e11",
                cancelButtonColor: "#111",
                confirmButtonText: "Delete it!",
            }).then((result) => {
                if (result.value) {
                this.$store.dispatch("changeLoaderValue",true)
                this.$http.delete(`/coupons/${itemId}/delete`)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("DELETE_COUPON", itemId)
                    }
                })
                }
            });
        },
        initializeAll(){
            this.newCoupon = {
                code: "",
                multiplier: null,
                start: null,
                end: null,
                is_active: false,
                description: ""
            }
            this.editMode = false;
            this.popupModalShow = false;
            this.$validator.reset()
        },
        fetchCoupons() {
            if(this.coupons.length){ return }
            this.isLoading = true
            this.$http.get(`/coupons`)
            .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.$store.commit('SET_COUPONS', response.data.data)
                }
            })
        },
    },
    created(){
        this.fetchCoupons()
    }
}
</script>
